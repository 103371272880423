<template>
     <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                  <div class="kt-list">
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 kt-shape-bg-color-4 text-white">
                        <span>HH:mm - HH:mm</span>
                        <span class="float-right">x / y</span>
                      </div>
                      <div class="col-md-8">
                        <span>Jadwal dokter yang sudah lewat. Jadwal tersedia sebanyak y, x adalah jumlah reservasi</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2 bg-primary text-white">
                        <span>HH:mm - HH:mm</span>
                        <span class="float-right">x / y</span>
                      </div>
                      <div class="col-md-8">
                        <span>Jadwal dokter hari ini dst. Jadwal tersedia sebanyak y, x adalah jumlah reservasi</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100">
                        <span class="fc-more">+xx more</span>
                      </div>
                      <div class="col-md-8">
                        <span>Terdapat Jadwal dokter sebanyak xx. Klik untuk melihat lebih lengkap.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i class="fas fa-plus"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk menambahkan reservasi baru.</span>
                      </div>
                    </div>
                    <div class="kt-list__item">
                      <div class="col-md-4 h-100 rounded p-2">
                        <span class="btn btn-default btn-circle btn-elevate btn-icon">
                            <i class="fas fa-expand"></i>
                        </span>
                      </div>
                      <div class="col-md-8">
                        <span>Klik untuk Menampilkan fullscreen.</span>
                      </div>
                    </div>
                </div>
                </div>
                <div class="modal-footer row justify-content-center m-0">
                      <button type="submit" class="btn btn-primary" @click="close()">{{ $t('button.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
    };
  },
  props: {
    modalName: { type: String, default:"modalHelp"},
  },
  methods: {
    ...mapMutations({
        setShowModalHelp: "MonthCalendarStore/SET_SHOW_MODAL_HELP"
    }),
    close() {
        $("#" + this.modalName).modal("hide");
        this.setShowModalHelp(false);
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
  }
};
</script>
