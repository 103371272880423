<template>
    <div>
        <div class="row flex-row-reverse mb-3">
            <div class="col-sm-12 col-md-auto">
                <button
                    v-if="loadingApi"
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon mt-2"
                >
                    <div class="spinner-border text-primary">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon mt-2"
                    @click="openModalReservasi()"
                >
                    <i class="fas fa-plus"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon mt-2"
                    @click="showHelp()"
                >
                    <i class="fas fa-question"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon mt-2"
                    @click="toggle"
                >
                    <i class="fas fa-expand"></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <FullCalendar
                    ref="fullCalendar"
                    defaultView="dayGridMonth"
                    :plugins="calendarPlugins"
                    :schedulerLicenseKey="'GPL-My-Project-Is-Open-Source'"
                    :header="false"
                    :events="events"
                    :eventRender="eventRender"
                    :views="views"
                    :eventLimitClick="eventLimitClick"
                    :navLinks="true"
                    :navLinkDayClick="navLinkDayClick"
                ></FullCalendar>
                <modal-more-event
                    ref="modal"
                    :date="selectedDate"
                ></modal-more-event>
                <modal-help v-if="showModalHelp"></modal-help>
                <modal-reservasi v-if="showModalReservasi"></modal-reservasi>
            </div>
        </div>
    </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import { mapState, mapMutations, mapActions } from "vuex";
import CalendarEvent from "./_components/CalendarEvent";
import ModalMoreEvent from "./_components/ModalMoreEvent";
import ModalHelp from "./_components/ModalHelp";
import LoadSpinner from "./../../_general/LoadSpinner";
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();

export default {
    components: {
        FullCalendar,
        ModalMoreEvent,
        ModalHelp,
        ModalReservasi: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-reservasi-dokter" */ "../ModalReservasi/ModalReservasi.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            calendarPlugins: [dayGridPlugin],
            views: {
                dayGrid: {
                    eventLimit: 2
                }
            },
            eventRender: function(info) {
                // console.log(info);
                const data = JSON.parse(info.event.title);
                let calendarEvent = Vue.extend(CalendarEvent);
                let instance = new calendarEvent({
                    propsData: {
                        xVue: vx.$store,
                        data: data,
                        date: moment(info.event.start).format("YYYY-MM-DD")
                    }
                });
                instance.$mount(info.el.querySelector(".fc-content"));
            },
            eventLimitClick: function(info) {
                vx.selectedDate = moment(info.date).format("YYYY-MM-DD");
                vx.$refs.modal.open();
                // vx.$emit('showMore', info.date)
            },
            navLinkDayClick: function(date, jsEvent) {
                vx.$store.dispatch("DoctorAppointmentStore/SETTING_TYPE", 2);
                setTimeout(() => {
                    vx.$store.dispatch(
                        "DoctorAppointmentStore/GO_TO_DATE",
                        moment(date).format("YYYY-MM-DD")
                    );
                    vx.$store.dispatch(
                        "DoctorAppointmentStore/RELOAD_CALENDAR"
                    );
                }, 10);
            },
            selectedDate: null
        };
    },
    computed: {
        ...mapState("MonthCalendarStore", {
            events: state => state.events,
            loadingApi: state => state.loadingApi,
            showModalHelp: state => state.showModalHelp
        }),
        ...mapState("DoctorAppointmentStore", {
            showModalReservasi: state => state.showModalReservasi
        })
    },
    methods: {
        ...mapMutations({
            setCalendarApi: "DoctorAppointmentStore/SET_CALENDAR_API",
            setShowModalHelp: "MonthCalendarStore/SET_SHOW_MODAL_HELP",
            setShowModalReservasi:
                "DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI",
            setFromEvent: "DayCalendarStore/SET_FROM_EVENT",
            setIsMonthCallendar: "DoctorAppointmentStore/SET_IS_MONTH_CALENDAR"
        }),
        ...mapActions({
            getEvent: "MonthCalendarStore/GET_EVENT",
            today: "DoctorAppointmentStore/TODAY",
            reloadCalendar: "DoctorAppointmentStore/RELOAD_CALENDAR"
        }),
        showHelp() {
            this.setShowModalHelp(true);
        },
        toggle() {
            this.$emit("clickToggle", true);
        },
        openModalReservasi() {
            this.setFromEvent(false);
            this.setShowModalReservasi(true);
        }
    },
    mounted() {
        this.setCalendarApi(this.$refs.fullCalendar.getApi());
        if (
            checkPermission.isCanAccess("DokterResource.GET.Dokter.Current") &&
            !this.getMonthCalendar
        ) {
            this.setIsMonthCallendar();
            this.today();
            this.reloadCalendar();
        }
    }
};
</script>

<style lang="scss" scoped></style>
