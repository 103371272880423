<template>
    <div
        class="text-white p-2 rounded h-100 pointer"
        :class="classBackground"
        @click="eventClick"
    >
        <div class="d-flex flex-column px-1">
            <div>{{ data.nama }}</div>
            <div>{{ data.namaIndo }}</div>
            <div class="d-flex" :style="data.cuti ? 'text-decoration: line-through' : ''">
                <div class="w-50">
                    {{ data.jamMulai | timeFormat}} - {{ data.jamSelesai | timeFormat }}
                </div>
                <div class="w-50 text-right">
                    {{ data.reservasiCount }} /
                    {{ countSlot }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        xVue: {
            type: Object
        },
        data: {
            type: Object
        },
        date: {
            type: String
        }
    },
    filters: {
        timeFormat(time){
            let timeStr = "-"
            if (time) {
                timeStr = time.slice(0, -3)
            }
            return timeStr
        }
    },
    computed: {
        classBackground() {
            if(moment(this.date).isBefore(moment(), "day")){
                return "kt-shape-bg-color-4"
            } else {
                return this.data.cuti ?  "kt-shape-bg-color-4" : "bg-primary";
            }
        },
        countSlot: function() {
            // cari durasi minute jam praktek
            let slot = '-'
            if (this.data.jamMulai && this.data.jamSelesai) {
                const jamMulai = this.data.jamMulai.split(":");
                const jamSelesai = this.data.jamSelesai.split(":");
                const minuteJamMulai =
                    parseFloat(jamMulai[0] * 60) + parseFloat(jamMulai[1]);
                const minuteJamSelesai =
                    parseFloat(jamSelesai[0] * 60) + parseFloat(jamSelesai[1]);
                const durationMinute = minuteJamSelesai - minuteJamMulai;

                // asumsi satu slot 15 menit
                const durationSlot = (this.data.durationInMinute != null) ? this.data.durationInMinute : 15;
                slot = Math.floor(durationMinute / durationSlot);
            }
            return slot
        }
    },
    methods: {
        eventClick() {
            this.xVue.dispatch("DoctorAppointmentStore/SETTING_TYPE", 2);
            setTimeout(() => {
                this.xVue.dispatch(
                    "DoctorAppointmentStore/GO_TO_DATE",
                    this.date
                );
                this.xVue.commit('DoctorAppointmentStore/SET_DATE', this.date)
                this.xVue.dispatch("DoctorAppointmentStore/RELOAD_CALENDAR");
            }, 100);
            this.$emit("close", true);
        }
    }
};
</script>

<style lang="scss" scoped></style>
