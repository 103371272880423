<template>
    <div
        id="moreEventMonthCalendar"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ date | filterDate }}</h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close()"
                    ></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="kt-input-icon kt-input-icon--left">
                            <input
                                v-model="textSearch"
                                type="text"
                                class="form-control form-control-sm input-50-radius"
                                placeholder="Cari nama dokter atau poli"
                            />
                            <span
                                class="kt-input-icon__icon kt-input-icon__icon--left"
                            >
                                <span><i class="la la-search"></i></span>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-4 px-2 py-3"
                            v-for="(event,index) in listEvent"
                            :key="`${event.data.dokterId}-${index}`"
                        >
                            <calendar-event
                                :data="event.data"
                                :xVue="xVue"
                                :date="event.start"
                                @close="close"
                            ></calendar-event>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import CalendarEvent from "./CalendarEvent";
export default {
    props: {
        date: {
            type: String
        }
    },
    data() {
        var vx = this;
        return {
            xVue: vx.$store,
            textSearch: ""
        };
    },
    components: {
        CalendarEvent
    },
    computed: {
        ...mapState("MonthCalendarStore", {
            events: state => state.events
        }),
        listEvent() {
            return this.events
                .filter(x => x.start == this.date)
                .map(y => {
                    return {
                        start: y.start,
                        data: JSON.parse(y.title)
                    };
                })
                .filter(z => {
                    const namaDokter =
                        z.data.nama
                            .toLowerCase()
                            .indexOf(this.textSearch.toLowerCase()) > -1;
                    const namaPoli =
                        z.data.namaIndo
                            .toLowerCase()
                            .indexOf(this.textSearch.toLowerCase()) > -1;
                    if (namaDokter || namaPoli) return z;
                })
        }
    },
    methods: {
        close() {
            $("#moreEventMonthCalendar").modal("hide");
        },
        open() {
            $("#moreEventMonthCalendar").modal();
        }
    },
    filters: {
        filterDate: function(value) {
            let date = "-"
            if (value) {
                date = moment(value).format("DD MMMM YYYY");
            }
            return date
        }
    }
};
</script>

<style lang="scss" scoped></style>
