<template>
    <div>
        <div class="row flex-row-reverse mb-3">
            <div class="col-sm-12 col-md-auto">
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    v-if="loading"
                >
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
                <sound-notifiction ref="notif"></sound-notifiction>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    @click="configPrinter()"
                >
                    <i class="fas fa-print"></i>
                    <i class="fas fa-cog" style="margin-left: 2px"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    @click="openModalInternal()"
                >
                    <i class="far fa-calendar-plus"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    @click="openModal(false)"
                >
                    <i class="fas fa-plus"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    @click="openModalHelp()"
                >
                    <i class="fas fa-question"></i>
                </button>
                <button
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                    @click="history"
                >
                    <i class="fas fa-history"></i>
                </button>
                <button
                    @click="toggle"
                    type="button"
                    class="btn btn-default btn-elevate btn-circle btn-icon"
                >
                    <i class="fas fa-expand"></i>
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
            <vue-date-picker
                v-model="selectedDate"
                :inline="true"
                :type="'date'"
            ></vue-date-picker>
        </div>
        <panel-info></panel-info>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import VueDatePicker from "./../../../_general/VueDatePicker";
import SlotJadwalType from "./../../../../constants/slot-jadwal-type-enum";
import PanelInfo from "./PanelInfo.vue";
import SoundNotifiction from "./../../../_general/SoundNotification";

export default {
    components: {
        VueDatePicker,
        PanelInfo,
        SoundNotifiction
    },
    data() {
        return {
            SlotJadwalType: SlotJadwalType
        };
    },
    computed: {
        ...mapState("DoctorAppointmentStore", {
            date: state => state.date
        }),
        ...mapState("DayCalendarStore", {
            loading: state => state.loadingEvent
        }),
        selectedDate: {
            get() {
                return this.date;
            },
            set(newValue) {
                this.goToDate(newValue);
                this.reloadCalendar();
            }
        }
    },
    methods: {
        ...mapMutations({
            setShowModalReservasi:
                "DoctorAppointmentStore/SET_SHOW_MODAL_RESERVASI",
            setFromEvent: "DayCalendarStore/SET_FROM_EVENT",
            setShowModalInternal: "DayCalendarStore/SET_SHOW_MODAL_INTERNAL",
            setShowModalBlock: "DayCalendarStore/SET_SHOW_MODAL_BLOCK",
            setShowModalHelp: "DayCalendarStore/SET_SHOW_MODAL_HELP",
            setSettingPrinter: "CallerStore/SET_SETTING_PRINTER",
            setShowModalPrinter: "CallerStore/SET_SHOW_MODAL_PRINTER",
            setShowModalHistory: "DayCalendarStore/SET_SHOW_MODAL_HISTORY"
        }),
        ...mapActions({
            goToDate: "DoctorAppointmentStore/GO_TO_DATE",
            reloadCalendar: "DoctorAppointmentStore/RELOAD_CALENDAR"
        }),
        openModal(from) {
            this.$store.commit('DayCalendarStore/SET_SELECTED_EVENT', null)
            this.setFromEvent(from);
            this.setShowModalReservasi(true);
        },
        openModalInternal() {
            this.setShowModalInternal(true);
        },
        openModalBlock() {
            this.setShowModalBlock(true);
        },
        openModalHelp() {
            this.setShowModalHelp(true);
        },
        toggle() {
            this.$emit("clickToggle", true);
        },
        notif() {
            this.$refs.notif.ring();
        },
        configPrinter() {
            this.setSettingPrinter(true);
            this.setShowModalPrinter(true);
        },
        history() {
            this.setShowModalHistory(true);
        }
    }
};
</script>

<style lang="scss" scoped></style>
